//export const API_BASE_URL = 'https://env-2405497.jcloud-ver-jpc.ik-server.com/api/';
// export const SERVER_BASE_URL = 'https://env-2405497.jcloud-ver-jpc.ik-server.com';
export const SERVER_BASE_URL = 'https://hcc-api.geekworkers.app';
// export const SERVER_BASE_URL = 'http://localhost:8000';
export const API_BASE_URL = SERVER_BASE_URL + '/api/';

// export const BASE_URL = 'https://env-6226974.jcloud-ver-jpc.ik-server.com/';
export const BASE_URL = 'https://hc-compta.geekworkers.app';
// export const BASE_URL = 'http://localhost:3000';

export const RoleName = {
    superAdmin: "superAdmin",
    admin: "admin",
    hcCompta: "HC Compta"
};


export const Status = {
    new: "new",
    toValidate: "toValidate",
    valid: "valid",
    refused: "refused"
};

export const RequestStatus = {
    draft: "draft",
    send: "send",
    waiting: "waiting",
    closed: "closed"
};

export const ClientType = {
    private: "private",
    company: "company" 
}

export const Frequence = {
    mensuel: "mensuel",
    trimestriel: "trimestriel",
    semestriel: "semestriel",
    annuel: "annuel",
    unique: "unique"
}

export const API_KEY_TINY_MCE = '40ors09zrly9tmcfpmqpadfgbt40ufzvk4l9apdf1fbpwn74'

export const ITEMS_NUMBER_PER_PAGE = 5

export const CUSTOMER_ROLE_NAME = "utilisateur"

export const MultiSelectString = {
  "allItemsAreSelected": "Tous les élements sont sélectionnés",
  "clearSearch": "Nettoyer",
  "clearSelected": "Désélectionner tous",
  "noOptions": "Aucun résultat",
  "search": "Recheche",
  "selectAll": "Tout slectionner",
  "selectAllFiltered": "Tout slectionner (Filtré)",
  "selectSomeItems": "Sélectionner...",
  "create": "Créer",
}