import axios from 'axios'
import { API_BASE_URL } from 'src/constants/app'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css';

const STD_TIMEOUT = 30000;

class HttpClient {

  client = axios.create({
    baseURL: API_BASE_URL,
    timeout: STD_TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  setAuthorization = (token) => {

    if (!token) {
      return;
    }

    this.client.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token || localStorage.getItem('token')}`

      return config
    })
  }

  setContentType = (type) => {

    if (!type) {
      return;
    }

    this.client.interceptors.request.use((config) => {
      config.headers["Content-Type"] = type;
      return config
    })
  }

  

  clearAuthorization = () => {
    delete this.client.defaults.headers.common.authorization
  }

  get = async (params) => {

    try {
        const rep = await this.client.get(params.url, params.data);
        return rep;
    } catch (error) {
      if (error.response) {

        if(error.response.data.error){
          for (const errorItem in error.response.data.error) {
            NotificationManager.error(error.response.data.error[errorItem], 'Erreur')
          }
        }
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        if(error.response.data.message === 'Token has expired') {
          localStorage.removeItem('token')
          localStorage.removeItem('customerHC')
          localStorage.removeItem('role')

          window.location.href = window.location.origin
        }
        return;
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        
        return;
      } else {
        // Something happened in setting up the request and triggered an Error
       
      }
      return null
    }
  }

  post = async (params) => {

    this.setAuthorization(localStorage.getItem('token'))
    this.setContentType('application/json')
    
    try {
      const rep = await this.client.post(params.url, params.data);
      if (!params.url.includes("filter") &&!params.url.includes("login") && !params.url.includes("updateStatusTokenRequest") && !params.url.includes("updateStatusDocumentRequest") ) {

        if(rep.data.error){
          for (const errorItem in rep.data.error) {
            NotificationManager.error(rep.data.error[errorItem], 'Erreur')
          }
        }else{
          //NotificationManager.success(res.data.message, 'Succès')
          NotificationManager.success("Opération effectuer avec succès", 'Succès')
        }
      }
      return rep;
    } catch (error) {
      if (error.response) {
        if(error.response.data.error){
          for (const errorItem in error.response.data.error) {
            NotificationManager.error(error.response.data.error[errorItem], 'Erreur')
          }
        }
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */


        if( error.response.data.message === "Login credentials are invalid."){
          NotificationManager.error("Veuillez vérifier l'email ou le mot de passe", 'Erreur')
        }else if(error.response.data.message){
          NotificationManager.error(error.response.data.message, 'Erreur')
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
      } else {
        // Something happened in setting up the request and triggered an Error
      }
      return null
    }
  }

  postWithFile = async (params) => {

    this.client = axios.create({
      baseURL: API_BASE_URL,
      timeout: STD_TIMEOUT,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })

    this.setAuthorization(localStorage.getItem('token'))

    try {
      const rep = await this.client.post(params.url, params.data)
      if(rep.data.error){
        for (const errorItem in rep.data.error) {
          NotificationManager.error(rep.data.error[errorItem], 'Erreur')
        }
      }else{
        //NotificationManager.success(res.data.message, 'Succès')
        NotificationManager.success("Opération effectuer avec succès", 'Succès')
      }
      return rep;
    } catch (error) {
      if (error.response) {
        if(error.response.data.error){
          for (const errorItem in error.response.data.error) {
            NotificationManager.error(error.response.data.error[errorItem], 'Erreur')
          }
        }
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
       
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
       
      } else {
        // Something happened in setting up the request and triggered an Error
       
      }
      return null
    }
  }

  put = async (params) => {
    try {
      return await this.client.put(params.url, params.data)
    } catch (e) {
      return null
    }
  }

  delete = async (params) => {
    try {
      return await this.client.delete(params.url, params.data)
    } catch (e) {
      return null
    }
  }
}

export default new HttpClient()
